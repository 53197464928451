<script lang="ts" setup></script>
<style scoped>
button.px-4.py-2.rounded.shadow.bg-primary.text-white.disabled\:bg-gray-500 {
  background-color: #208400;
}
</style>
<template>
  <button
    class="px-4 py-2 rounded shadow bg-primary text-white disabled:bg-gray-500"
  >
    <slot />
  </button>
</template>
